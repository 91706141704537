import { TranslocoFallbackStrategy, TRANSLOCO_FALLBACK_STRATEGY, TranslocoMissingHandler, translocoConfig, TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';

export class CustomStrategy implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string) {
    const fallbackLang: string[] = [];
    if (['nb', 'nn'].includes(failedLang)) {
      fallbackLang.push('no');
    }
    fallbackLang.push('en');
    return fallbackLang;
  }
}

export const customFallbackStrategy = {
  provide: TRANSLOCO_FALLBACK_STRATEGY,
  useClass: CustomStrategy
};

export class CustomMissingHandler implements TranslocoMissingHandler {
  handle(key: string) {
    if (key.startsWith('INSIGHT.')) {
      return null;
    }
    return key;
  }
}

export const customMissingHandlerStrategy = {
  provide: TRANSLOCO_MISSING_HANDLER,
  useClass: CustomMissingHandler
};
