import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, Translation, TranslocoLoader, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { ConfigurationService } from 'app/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { customFallbackStrategy, customMissingHandlerStrategy } from './custom-fallback-strategy';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  thingy: Record<string, Observable<Translation>> = {};
  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  getTranslation(lang: string) {
    lang = lang.replace('/', '');
    if (!this.thingy[lang]) {
      this.thingy[lang] = this.http.get<Translation>(`${this.configurationService.apiUrl}/api/languages/translate/${lang}`, { headers: { 'init': 'true' } })
        .pipe(shareReplay(1));
    }
    return this.thingy[lang];
  }
}

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        ko: 'ko-KO',
        ar: 'ar-AE',
        bg: 'bg-BG',
        cs: 'cs-CZ',
        da: 'da-DK',
        de: 'de-DE',
        el: 'el-GR',
        en: 'en-GB',
        es: 'es-ES',
        et: 'et-EE',
        fa: 'fa-IR',
        fi: 'fi-FI',
        fr: 'fr-FR',
        hr: 'hr-HR',
        hu: 'hu-HU',
        id: 'id-ID',
        is: 'is-IS',
        it: 'it-IT',
        ja: 'ja-JP',
        kn: 'kn-IN',
        ku: 'ku-KRM',
        lt: 'lt-LT',
        lv: 'lv-LV',
        ms: 'ms-ms',
        nl: 'nl-NL',
        no: 'nn-NO',
        pl: 'pl-PL',
        pt: 'pt-PT',
        'pt-br': 'pt-BR',
        ro: 'ro-RO',
        ru: 'ru-RU',
        sk: 'sk-SK',
        sl: 'sl-SL',
        so: 'so-SO',
        'sv': 'sv-SE',
        tr: 'tr-TR',
        th: 'th-TH',
        uk: 'uk-UK',
        vi: 'vi-VN',
        zh: 'zh-CN',
      }
    }),
    TranslocoMessageFormatModule.forRoot(),
  ],
  exports: [TranslocoModule],
  providers: [
    customFallbackStrategy,
    customMissingHandlerStrategy,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['cs', 'da', 'de', 'el', 'en', 'es', 'et', 'fi', 'fr', 'hu', 'id', 'is', 'it', 'ja', 'kn', 'ku', 'lt', 'lv',
          'ms', 'nl', 'no', 'pl', 'pt', 'pt-br', 'ro', 'ru', 'sk', 'sv', 'th', 'tr', 'uk', 'vi', 'zh-cn', 'bg', 'so', 'fa', 'ar', 'hr', 'sl', 'ko'],
        defaultLang: 'en',
        fallbackLang: ['en'],
        failedRetries: 0,
        missingHandler: {
          logMissingKey: false,
          useFallbackTranslation: true,
          allowEmpty: true,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
